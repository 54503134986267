

import React, { useEffect, useState } from "react";
import Sidebar from "../../CoustamComponents/Sidebar";
import AuthService from "../../../Services/AuthService";
import LoaderHelper from "../../../Services/Loading/LoaderHelper";
import moment from "moment";
import DataTable from "react-data-table-component";

const WithdrawalList = () => {
    const [withdrawalData, setWithdrawalData] = useState([]);

    const getWithdrawals = async () => {
        try {
            LoaderHelper.loaderStatus(true);
            const comissionPerDay = await AuthService.withdrawalRequestListUser();
            if (comissionPerDay?.success) {
                setWithdrawalData(comissionPerDay?.data?.reverse());
            }

        } finally { LoaderHelper.loaderStatus(false); }
    };

    const statusShow = (row) => {
        return (
            <div>
                <span className={`text-${row?.status === "PENDING" ? "warning" : row?.status === "APPROVED" ? "success" : "danger"}`}>{row?.status}</span>
            </div>
        )
    }

    const columns = [
        { name: 'Sr no.',width:"80px", selector: (row, index) => index + 1, wrap: true },
        { name: 'Date', sort: true, selector: row => moment(row?.createdAt).format('MMMM Do YYYY'), wrap: true },
        { name: 'Amount', selector: row => row?.amount, wrap: true },
        { name: 'To Address', selector: row => row?.to_address, wrap: true },
        { name: 'Transaction Hash', selector: row => row?.transaction_hash || "---", wrap: true },
        { name: 'Status', selector: statusShow, wrap: true },

    ];




    useEffect(() => {
        getWithdrawals()
    }, []);


    return (
        <>
            <div className="wrapper dashboard_page ">
                <div className=" page-body d-flex align-items-stretch">
                    <Sidebar />
                    <div className="page-content">
                        <div className="bg-dash-dark-2 py-4">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between" >
                                    <h2 className="h5 mb-0">Withdrawal Request</h2>
                                </div>
                            </div>
                        </div>
                        <section>
                            <div className="grid-container row g-4" >
                                <div className="col-lg-12 " >


                                    <DataTable
                                        pagination
                                        direction="auto"
                                        responsive
                                        subHeaderAlign="right"
                                        subHeaderWrap
                                        striped
                                        highlightOnHover
                                        fixedHeader
                                        columns={columns}
                                        data={withdrawalData}
                                    />

                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>


        </>
    );
}

export default WithdrawalList