
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../../../CoustamComponents/Sidebar";
import LoaderHelper from "../../../../Services/Loading/LoaderHelper";
import AuthService from "../../../../Services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../../Services/CustomAlertMessage";
import { ApiConfig } from "../../../../Services/AuthAction/ApiConfig";
import { ProfileContext } from "../../../../Services/ProfileProvider";

const EditProfile = () => {

  const { handleUserDetails, userDetails } = useContext(ProfileContext);
  const [imageError, setImageError] = useState(false);
  const navigate = useNavigate();

  const [formData, setFormData] = useState(
    {
      userName: "",
      email: "",
      image: "",
      imagePath: "",
      mobileNumber: "",
      isImageUpdated: false,
    }
  );

  console.log(formData.isImageUpdated, 'isImageUpdated==');

  useEffect(() => {
    if (userDetails) {
      setFormData({
        userName: userDetails.userName || "",
        mobileNumber: userDetails.phoneNumber || "",
        email: userDetails.email || "",
        image: ApiConfig.baseUrl + userDetails?.profilePicture || "",
        imagePath: userDetails?.profilePicture || "",
      });
    }
  }, [userDetails]);

  const handleInput = (event) => {
    const { name, value, files } = event.target;
    if (name === 'image' && files && files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = () => {
        setFormData(prevState => ({
          ...prevState,
          image: reader.result,
          imagePath: file,
          isImageUpdated: true,
        }));
        setImageError(false);
      };
      reader.readAsDataURL(file);
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleUpdateProfile = async () => {
    var formValue = new FormData();
    if (formData.isImageUpdated === true) {
      formValue.append('partner-iamge', formData.imagePath);
    }
    formValue.append("userName", formData.userName);
    formValue.append("phoneNumber", formData.mobileNumber);
    formValue.append("email", formData.email);
    LoaderHelper.loaderStatus(true);
    try {
      const result = await AuthService.editprofile(formValue);
      LoaderHelper.loaderStatus(false);
      if (result?.success) {
        alertSuccessMessage(result?.message);
        handleUserDetails();
        navigate('/profile')
      } else {
        alertErrorMessage(result);
      }
    } catch (error) {
      LoaderHelper.loaderStatus(false);
      alertErrorMessage(error);
    }
  };
  return (

    <div className="wrapper">
      <div className="d-flex align-items-stretch">
        <Sidebar />
        <div className="page-content">
          <div className="bg-dash-dark-2 py-4">
            <div className="container-fluid">
              <h2 className="h5 mb-0">Profile</h2>{" "}
            </div>
          </div>
          <div className="container-fluid py-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb py-2 px-0">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Profile
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Edit Profile
                </li>
              </ol>
            </nav>
          </div>
          <section className="pt-0">
            <div className="container-fluid">
              <div className="row gy-4">
                <div className="col-xxl-6 col-lg-8 m-auto">
                  <div className="card  h-100">
                    <div className="card-body ">
                      <form>
                        <div className="col-md-12">
                          <div className="img-prev">
                            <div className="avatar-preview">

                              <img
                                src={formData.image || "images/user_thumb.png"}
                                className="img-fluid"
                                alt="Profile"
                                onError={(e) => {
                                  if (!imageError) {
                                    e.target.onerror = null;
                                    e.target.src = "images/user_thumb.png";
                                    setImageError(true);
                                  }
                                }}
                              />

                            </div>

                            <div className="avatar-edit">
                              <input type="file" id="imageUpload" name="image" onChange={handleInput} />
                              <label htmlFor="imageUpload">
                                <i className="ri-upload-cloud-2-line me-2"></i>Upload Photo
                              </label>
                              <small>Click button to upload photo</small>
                            </div>
                          </div>
                        </div>

                        <div className="mb-3">
                          <label className="form-label" htmlFor="exampleInputName">UserName</label>
                          <input className="form-control" id="exampleInputName" type="text" name="userName" value={formData.userName} onChange={handleInput} />
                        </div>

                        <div className="mb-3">
                          <label className="form-label" htmlFor="exampleInputEmail1">Email ID</label>
                          <input className="form-control" id="exampleInputEmail1" type="email" name="email" value={formData.email} onChange={handleInput} />
                        </div>

                        <div className="mb-3">
                          <label className="form-label" htmlFor="exampleInputMobile">Mobile No</label>
                          <input className="form-control" id="exampleInputMobile" type="tel" name="mobileNumber" value={formData.mobileNumber} onChange={handleInput} />
                        </div>

                        <button className="btn btn-primary w-100 mt-3" type="button" onClick={handleUpdateProfile}>Update Profile</button>

                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>

  );
}

export default EditProfile
