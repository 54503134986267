import { ApiConfig } from "../AuthAction/ApiConfig";
import { ApiCallGET, ApiCallPost, ApiCallPut } from "../AuthAction/Apicall";

const AuthService = {

  login: async (formData) => {
    const { baseAuth, login } = ApiConfig;
    const url = baseAuth + login;
    const params = {
      email: formData.emailId,
      password: formData.password,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  getuserProfile: async () => {
    const token = sessionStorage.getItem('token');
    const { baseAuth, userProfile } = ApiConfig;
    const url = baseAuth + userProfile;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    return ApiCallGET(url, headers);
  },

  coinCommission: async () => {
    const token = sessionStorage.getItem('token');
    const { baseAuth, calculate_coin_commission } = ApiConfig;
    const url = baseAuth + calculate_coin_commission;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    return ApiCallGET(url, headers);
  },

  coinCommissionPreviousMonth: async () => {
    const token = sessionStorage.getItem('token');
    const { baseAuth, calculate_coin_commission_previous_month } = ApiConfig;
    const url = baseAuth + calculate_coin_commission_previous_month;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    return ApiCallGET(url, headers);
  },

  comissionPerDay: async () => {
    const token = sessionStorage.getItem('token');
    const { baseAuth, comissionPerDay } = ApiConfig;
    const url = baseAuth + comissionPerDay;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    return ApiCallGET(url, headers);
  },

  partner_monthly_payouts_list: async () => {
    const token = sessionStorage.getItem('token');
    const { baseAuth, partner_monthly_payouts_list } = ApiConfig;
    const url = baseAuth + partner_monthly_payouts_list;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    return ApiCallGET(url, headers);
  },

  withdrawalRequest: async (email_or_phone, otp, withdrawal_address, amount) => {
    const token = sessionStorage.getItem('token');
    const { baseAuth, withdrawalRequest } = ApiConfig;
    const url = baseAuth + withdrawalRequest;
    const params = { email_or_phone, otp, withdrawal_address, amount }
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    return ApiCallPost(url, params, headers);
  },

  forgot_password: async (email, verification_code, new_password) => {
    const { baseAuth, forgot_password } = ApiConfig;
    const url = baseAuth + forgot_password;
    const params = { email, verification_code, new_password }
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },


  editprofile: async (formData) => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, update_profile } = ApiConfig;
    const url = baseAuth + update_profile;
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    };
    return ApiCallPost(url, formData, headers);
  },

  getUserfunds: async () => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, userfunds } = ApiConfig;
    const url = baseAuth + userfunds;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGET(url, headers);
  },

  pairPrice: async () => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, pairPrice } = ApiConfig;
    const url = baseAuth + pairPrice;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGET(url, headers);
  },

  withdrawalRequestListUser: async () => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, withdrawalRequestListUser } = ApiConfig;
    const url = baseAuth + withdrawalRequestListUser;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGET(url, headers);
  },

  user_growth_data: async () => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, user_growth_data } = ApiConfig;
    const url = baseAuth + user_growth_data;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGET(url, headers);
  },

  operation_countries: async () => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, operation_countries } = ApiConfig;
    const url = baseAuth + operation_countries;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGET(url, headers);
  },

  partners_growth_data: async () => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, partners_growth_data } = ApiConfig;
    const url = baseAuth + partners_growth_data;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGET(url, headers);
  },

  getpartnerships: async () => {
    const token = sessionStorage.getItem("token");
    const { baseUser, getpartnerships } = ApiConfig;
    const url = baseUser + getpartnerships;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGET(url, headers);
  },

  quickBuySellHistory: async (skip, limit) => {
    const token = sessionStorage.getItem("token");
    const { baseQuickBuy, all_history } = ApiConfig;
    const url = baseQuickBuy + all_history;
    const params = {
      skip, limit
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  getOtp: async (signid, type) => {
    const { baseUser, getOtp } = ApiConfig;
    const url = baseUser + getOtp;
    const params = {
      email_or_phone: signid,
      type: type,
      "resend": true
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  tradingCommission: async (skip, limit) => {
    const token = sessionStorage.getItem("token");
    const { baseAdmin, tradingCommission } = ApiConfig;
    const url = baseAdmin + tradingCommission;
    const params = {
      skip: skip,
      limit: limit
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },

  withdrawalFees: async (skip, limit) => {
    const token = sessionStorage.getItem("token");
    const { baseAdmin, withdrawalFees } = ApiConfig;
    const url = baseAdmin + withdrawalFees
    const params = {
      skip, limit
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

};

export default AuthService;
