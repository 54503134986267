import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const PartnerGrowth = ({ categories, data }) => {
    const [chartData, setChartData] = useState({
        series: [
          {
            name: 'Partners',
            data: data || [],
          },
        ],
        options: {
          chart: {
            type: 'line',
            height: 350,
          },
          stroke: {
            curve: 'smooth',
            width: 3,
            colors: ['#00E396'],
          },
          xaxis: {
            categories: categories || [],
          },
          tooltip: {
            y: {
              formatter: (val) => `${val} Partners`,
            },
          },
        },
      });
    
      useEffect(() => {
        setChartData((prev) => ({
          ...prev,
          series: [{ name: 'Partners', data }],
          options: { ...prev.options, xaxis: { categories } },
        }));
      }, [categories, data]);
    
      return (
        <div>
          <Chart options={chartData.options} series={chartData.series} type="line" height={350} />
        </div>
      );
    };
export default PartnerGrowth;