

import React, { useEffect, useState } from "react";
import Sidebar from "../../CoustamComponents/Sidebar";
import AuthService from "../../../Services/AuthService";
import LoaderHelper from "../../../Services/Loading/LoaderHelper";
import moment from "moment";
import ReactPaginate from 'react-paginate';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import DataTable from "react-data-table-component";
import { ApiConfig } from "../../../Services/AuthAction/ApiConfig";

const QuickBuySellCommission = () => {
    const [commissionData, setCommissionData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(100);
    const [totalData, setTotalData] = useState()


    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected + 1);
    };

    const pageCount = totalData / itemsPerPage
    const skip = (currentPage - 1) * itemsPerPage;

    const getTotalComission = async (skip, limit) => {
        try {
            LoaderHelper.loaderStatus(true);
            const comissionPerDay = await AuthService.quickBuySellHistory(skip, limit);
            if (comissionPerDay?.success) {
                let filteredItem = comissionPerDay?.data
                setCommissionData(filteredItem);
                setTotalData(comissionPerDay?.totalCount)
            }

        } finally { LoaderHelper.loaderStatus(false); }
    };

    const assetFormater = (row) => {
        return (
            <><img width="30px" src={`${ApiConfig?.baseUrl}/${row?.fromCurrIcon}`} className="mx-2" />{row.from} </>
        )
    }


    const assetFormater2 = (row) => {
        return (
            <><img width="30px" src={`${ApiConfig?.baseUrl}/${row?.toCurrIcon}`} className="mx-2" />{row.to} </>
        )
    }

    const toFixed = (data) => parseFloat(data?.toFixed(6))

    const columns = [
        { name: "Sr No.", wrap: true, selector: (row, index) => skip + 1 + index, },
        { name: "Date", width: "170px", selector: row => moment(row?.createdAt).format("MMM Do YYYY hh:mm A"), wrap: true },
        { name: "User Id", width: "150px", wrap: true, selector: row => row.userId, },
        { name: "From Asset", width: "120px", wrap: true, selector: assetFormater, },
        { name: "To Asset", width: "120px", wrap: true, selector: assetFormater2, },
        { name: "Pay Amount", width: "120px", wrap: true, selector: row => toFixed(row.pay_amount), },
        { name: "Get Amount", width: "120px", wrap: true, selector: row => toFixed(row.get_amount), },
        { name: "Fee", width: "100px", wrap: true, selector: row => toFixed(row.fee || 0), },
        { name: "Fee Percentage", width: "150px", wrap: true, selector: row => row.fee_percentage || 0, },
    ];



    useEffect(() => {
        getTotalComission(skip, 100)
    }, [currentPage, skip]);


    const showNotification = () => {
        let notifyContainer = document.getElementsByClassName("notification-container")[0]
        if (notifyContainer.classList.contains("d-none")) {
            notifyContainer.classList.add("active")
            notifyContainer.classList.remove("d-none")
        } else {
            notifyContainer.classList.add("d-none")
            notifyContainer.classList.remove("active")
        }
    }


    // *********Export Trade Data In PDF Formats ************* // 
    const exportToPDF = () => {
        const doc = new jsPDF('landscape', 'mm', 'a4');
        doc.text('Quick Buy Sell Commission Report', 100, 10);

        const tableHeaders = ['Serial No.', 'Date/Time', 'Asset', 'Fee', 'Fee Percentage', 'From User', 'Pay Amount', 'Get Amount'];
        const tableData = commissionData.map((trade, index) => [
            index + 1,
            moment(trade?.createdAt).format("MMMM Do YYYY, h:mm:ss a"),
            `${trade?.from} - ${trade?.to}`,
            toFixed(trade?.fee),
            trade?.fee_percentage,
            trade?.userId,
            toFixed(trade?.pay_amount),
            toFixed(trade?.get_amount),
        ]);
        const fixedColumnWidths = [30, 30, 30, 30, 30, 30, 30, 30,];
        const columnStyles = {};
        tableHeaders.forEach((_, index) => {
            columnStyles[index] = { cellWidth: fixedColumnWidths[index] };
        });

        doc.autoTable({
            head: [tableHeaders],
            body: tableData,
            theme: 'grid',
            columnStyles: columnStyles,
        });

        doc.save('CVTrade-Partner-Quick-Buy-Sell-Commission.pdf');
    };

    return (
        <>
            <div className="wrapper dashboard_page ">
                <div className=" page-body d-flex align-items-stretch">
                    <Sidebar />
                    <div className="page-content">
                        <div className="bg-dash-dark-2 py-4">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between" >
                                    {/* <h2 className="h5 mb-0">Dashboard</h2> */}
                                    <div className="main_search" >
                                        {/* <input className="form-control" type="search" placeholder="Search.." />
                                        <i class="ri-search-2-line"></i> */}
                                    </div>
                                    <div className="nofti_bar" >
                                        <button className="nofti_btn" onClick={showNotification}>
                                            <span class="alert_badge"></span>
                                            <i class="ri-notification-4-fill"></i>
                                        </button>
                                        <div class="notification-container d-none">
                                            <h3>Notifications </h3>
                                            {/* <label class="notification new" for="size_1"><em>1</em> new <a href="">guest account(s)</a> have been created.</label> */}
                                            <label class="notification new text-center" for="size_1"> No notification</label>

                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>
                        <section>
                            <div className="pg_title  " >
                                <div className="d-flex align-items-start justify-content-between mb-2 mb-md-3" >
                                    <div>
                                        <h2 className="h5 mb-0 mb-3"> Quick Buy Sell Commission Report</h2>
                                        <nav aria-label="breadcrumb mb-0">
                                            <ol class="breadcrumb">
                                                <li class="breadcrumb-item"><a href="/">Dashboard</a></li>
                                                <li class="breadcrumb-item active" aria-current="page">Quick Buy Sell Commission Report </li>
                                            </ol>
                                        </nav>
                                    </div>
                                    <button className="btn btn-light bg-white export_btn" onClick={exportToPDF}> <i class="ri-download-2-line me-2 ri-xl"></i> Export </button>
                                </div>
                            </div>
                            <div className="grid-container table_card" >
                                {/* <div className="col-lg-12 " > */}


                                <DataTable
                                    direction="auto"
                                    responsive
                                    subHeaderAlign="right"
                                    subHeaderWrap
                                    striped
                                    highlightOnHover
                                    fixedHeader
                                    columns={columns}
                                    data={commissionData}

                                />

                                {/* </div> */}
                                {totalData > 5 ? <ReactPaginate
                                    pageCount={pageCount}
                                    onPageChange={handlePageChange}
                                    containerClassName={'customPagination'}
                                    activeClassName={'active'}
                                /> : ""}
                            </div>
                        </section>
                    </div>
                </div>
            </div>


        </>
    );
}

export default QuickBuySellCommission
