import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useContext, useState } from "react";
import { ApiConfig } from "../../../Services/AuthAction/ApiConfig";
import { ProfileContext } from "../../../Services/ProfileProvider";

const Sidebar = () => {


  const emailAddress = sessionStorage.getItem('emailAddress');
  const { setLoginDetails, userDetails } = useContext(ProfileContext);
  const [isToggle, setIsToggle] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    sessionStorage.clear();
    setLoginDetails({})
    navigate("/");
  }


  return (
    <nav id="sidebar" className={`${isToggle === true ? 'active' : 'sidebar-fixed'}`}>
      <div className=" d-flex align-items-center py-2 p-4  ">
        <img src="images/logo.svg" width="190" className="navbar-brand" />
        <button className="navbar-toggler ms-2 d-block d-xl-none" onClick={() => setIsToggle(!isToggle)}><span className="navbar-toggler-icon"></span></button>
      </div>
      {/* < hr /> */}
      <div className="sidebar-header d-flex align-items-center p-4   ">

        <img src={userDetails?.profilePicture ? `${ApiConfig.baseUrl + userDetails.profilePicture}` : "images/user.png"} onError={(e) => e.target.src = "images/user_thumb.png"} alt="user avatar" />

        <div className="ms-3 title">
          
          <h1 className="h5 mb-1">
            {userDetails?.userName || '---'}

            <br/>
            <small>{emailAddress}</small>
          </h1>

        </div>
      </div>
      <ul className="list-unstyled side_scroll">
        <li className="menu-heading"><h5 className="overline-title text-primary-alt"> Menu </h5></li>
        <li className='sidebar-item' >
          <Link to="/dashboard" className={`sidebar-link ${(location.pathname === '/dashboard') && 'active'}`}>
            <span className="svg-icon svg-icon-sm svg-icon-heavy">
              <i className="ri-home-2-line"></i>
            </span>
            <span>Dashboard </span>
          </Link>
        </li>

        <li className="sidebar-item">
          <a href="#exampledropdownDropdown3" data-bs-toggle="collapse" className={`sidebar-link ${(location.pathname === '/profile' || location.pathname === '/editProfile') && 'active'}`}>
            <span className="svg-icon svg-icon-sm svg-icon-heavy"> <i className="ri-user-line"></i> </span>
            <span>Profile</span>
          </a>
          <ul className={`collapse list-unstyled ${(location.pathname === '/profile' || location.pathname === '/editProfile') && 'show'}`} id="exampledropdownDropdown3" >
            <li> <Link to="/profile" className={`sidebar-link ${(location.pathname === '/profile') && 'active'}`}> My Profile </Link> </li>
            <li> <Link to="/editProfile" className={`sidebar-link ${(location.pathname === '/editProfile') && 'active'}`}> Edit Profile </Link> </li>
          </ul>
        </li>

   

        <li className='sidebar-item' >
          <Link to="/UserComissionReport" className={`sidebar-link ${(location.pathname === '/UserComissionReport') && 'active'}`}>
            <span className="svg-icon svg-icon-sm svg-icon-heavy">
            <i class="ri-luggage-deposit-fill"></i>
            </span>
            <span>Trading Commission Report </span>
          </Link>
        </li>

        <li className='sidebar-item' >
          <Link to="/WithdrawalComission" className={`sidebar-link ${(location.pathname === '/WithdrawalComission') && 'active'}`}>
            <span className="svg-icon svg-icon-sm svg-icon-heavy">
            <i class="ri-file-list-fill"></i>
            </span>
            <span>Withdrawal Commission Report </span>
          </Link>
        </li>

        <li className='sidebar-item' >
          <Link to="/comissionReport" className={`sidebar-link ${(location.pathname === '/comissionReport') && 'active'}`}>
            <span className="svg-icon svg-icon-sm svg-icon-heavy">
            <i class="ri-file-list-3-line"></i>
            </span>
            <span>Comission Credit </span>
          </Link>
        </li>

        <li className='sidebar-item' >
          <Link to="/WithdrawalList" className={`sidebar-link ${(location.pathname === '/WithdrawalList') && 'active'}`}>
            <span className="svg-icon svg-icon-sm svg-icon-heavy">
              <i className="ri-stack-fill"></i>
            </span>
            <span>Withdrawal Transactions </span>
          </Link>
        </li>


        {/* <li className='sidebar-item' >
          <Link to="/profile_report" className={`sidebar-link ${(location.pathname === '/profile_report') && 'active'}`}>
            <span className="svg-icon svg-icon-sm svg-icon-heavy">
              <i className="ri-article-line"></i>
            </span>
            <span>Profile's Report </span>
          </Link>
        </li> */}

        <li className='sidebar-item' >
          <Link to="/promotion_tools" className={`sidebar-link ${(location.pathname === '/promotion_tools') && 'active'}`}>
            <span className="svg-icon svg-icon-sm svg-icon-heavy">
              <i className="ri-store-3-fill"></i>
            </span>
            <span>Certificate</span>
          </Link>
        </li>
{/* 
        <li className='sidebar-item' >
          <Link to="/suggestions" className={`sidebar-link ${(location.pathname === '/suggestions') && 'active'}`}>
            <span className="svg-icon svg-icon-sm svg-icon-heavy">
              <i className="ri-blaze-line"></i>
            </span>
            <span>Suggestions</span>
          </Link>
        </li> */}

        <li className='sidebar-item' >
          <Link to="/notifications" className={`sidebar-link ${(location.pathname === '/notifications') && 'active'}`}>
            <span className="svg-icon svg-icon-sm svg-icon-heavy">
              <i className="ri-notification-line"></i>
            </span>
            <span>Notifications</span>
          </Link>
        </li>

      </ul>
      <div className="sidebar-item px-3 mt-3" >
        <button type="button" className="btn btn-danger w-100" onClick={handleLogout}>
          <span className="svg-icon svg-icon-sm svg-icon-heavy">
            <i className="ri-logout-circle-r-line"></i>
          </span>
          <span> Logout </span>
        </button>
      </div>
    </nav>
  );
}
export default Sidebar