import React, { useContext } from "react"
import { Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from "../../Components/SidebarPages/Dashboard";
import { ProfileContext } from "../ProfileProvider";
import LoginPage from "../../Components/AuthComponent/LoginPage";
import PageNotFound from "../../Components/CoustamComponents/PageNotFound";
import ProfilePage from "../../Components/SidebarPages/Profile/ProfilePage";
import EditProfile from "../../Components/SidebarPages/Profile/EditProfilePage";
import ComingSoonPage from "../../Components/CoustamComponents/ComingSoonPage";
import ComissionReport from "../../Components/SidebarPages/Comission Report";
import WithdrawalList from "../../Components/SidebarPages/WithdrawalList";
import UserComissionReport from "../../Components/SidebarPages/UserComissionReport";
import WithdrawalComission from "../../Components/SidebarPages/WithdrawalComission";
import Certificate from "../../Components/SidebarPages/Certificate";
import QuickBuySellCommission from "../../Components/SidebarPages/QuickBuySellCommission";
import ForgotPassword from "../../Components/AuthComponent/ForgotPassword";

const Routing = () => {

  const authToken = sessionStorage.getItem('token');
  const { loginDetails } = useContext(ProfileContext);

  return (
    <Routes>
      <Route exact path="/" element={authToken || loginDetails?.token ? <><Dashboard />  <Navigate to="/dashboard" replace /></> : <LoginPage />} />
      <Route exact path="/ForgotPassword" element={authToken || loginDetails?.token ? <><Dashboard />  <Navigate to="/dashboard" replace /></> : <ForgotPassword />} />

      <Route
        path="/dashboard"
        element={authToken || loginDetails?.token ? <Dashboard /> : <Navigate to="/" replace />}
      />
      <Route
        path="/comissionReport"
        element={authToken || loginDetails?.token ? <ComissionReport /> : <Navigate to="/" replace />}
      />

      <Route
        path="/WithdrawalList"
        element={authToken || loginDetails?.token ? <WithdrawalList /> : <Navigate to="/" replace />}
      />

      <Route
        path="/UserComissionReport"
        element={authToken || loginDetails?.token ? <UserComissionReport /> : <Navigate to="/" replace />}
      />

      <Route
        path="/WithdrawalComission"
        element={authToken || loginDetails?.token ? <WithdrawalComission /> : <Navigate to="/" replace />}
      />

      <Route
        path="/profile"
        element={authToken || loginDetails?.token ? <ProfilePage /> : <Navigate to="/" replace />}
      />

      <Route
        path="/editProfile"
        element={authToken || loginDetails?.token ? <EditProfile /> : <Navigate to="/" replace />}
      />
      <Route
        path="/Certificate"
        element={authToken || loginDetails?.token ? <Certificate /> : <Navigate to="/" replace />}
      />
      <Route
        path="/QuickBuySellCommission"
        element={authToken || loginDetails?.token ? <QuickBuySellCommission /> : <Navigate to="/" replace />}
      />

      <Route
        path="/*"
        element={authToken || loginDetails?.token ? <ComingSoonPage /> : <PageNotFound />}
      />

      <Route path="/*" element={<PageNotFound />} />
    </Routes>
  );
}
export default Routing