import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Sidebar from '../Sidebar';

const ComingSoonPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (


    <div className="wrapper">
      <div className="d-flex align-items-stretch">
        <Sidebar />
        <div className="page-content">
          <section className="pt-0">
            <div className="container-fluid">
              <div className="row gy-4">
                <div className="col-xxl-10 col-lg-8 m-auto">
                  <img src="/images/coming_soon.svg" className="img-fluid mb-5 show_light" />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>

  )
}

export default ComingSoonPage
