const appUrl = "https://backend.cvtrade.io";
// const appUrl = "http://localhost:4003";
export const ApiConfig = {
  // =========EndPoints==========
  login: "login",
  userProfile: "userProfile",
  update_profile: "updateProfile",  
  calculate_coin_commission: "calculate_coin_commission_permonth",  
  comissionPerDay: "calculate_coin_commission_perday",  
  partner_monthly_payouts_list: "partner_monthly_payouts_list",  
  userfunds: "user-wallet",
  getOtp: "send-otp",
  withdrawalRequest: "withdrawal-request",
  pairPrice: "cvt_usdt_price",
  withdrawalRequestListUser: "withdrawal-request-list-user",
  tradingCommission: "trading-commission",
  withdrawalFees: 'withdrawal_fees',
  // ============URLs================


  baseUrl: `${appUrl}/`,
  baseAuth: `${appUrl}/v1/partnerShip/`,
  baseWallet: `${appUrl}/v1/wallet/`,
  baseUser: `${appUrl}/v1/user/`,
  baseAdmin: `${appUrl}/v1/admin/`,
};