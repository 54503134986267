import React from "react";
import { Link } from 'react-router-dom';


const PageNotFound = () => {

    return (
        <section className="hero-section" id="home">
            <div className="container d-flex justify-content-center align-items-center">
                <div className="row align-items-center">
                    <div className=" col-lg-6 col-lg-6 ">
                        <h1 className="text-white mb-4"><em className="txt_gradient" >OOPS.. Page Not Found</em> </h1>
                        <img src="images/error.png" className="img-fluid img-mobile" />
                        <div className="d-flex align-items-center my-4 my-md-5" >
                            <Link className="btn custom-btn  btn-xl custom-border-btn  me-2 me-md-3" to="/dashboard">Back To HOme  </Link>
                        </div>
                    </div>
                    <div className=" col-lg-6 col-lg-6 text-center ">
                        <img src="images/stars_img.png" className="img-fluid star_img" />
                        <img src="images/error.png" className="img-fluid img-desktop" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PageNotFound