
import Routing from "./Services/Routing";
import { ProfileProvider } from "./Services/ProfileProvider";
import LoaderHelper from "./Services/Loading/LoaderHelper";
import Loading from "./Services/Loading";
import { ToastContainer } from "react-toastify";

function App() {

  return (

    <ProfileProvider>
      <Routing />
      <Loading ref={ref => LoaderHelper.setLoader(ref)} />
      <ToastContainer />
    </ProfileProvider>

  );
}

export default App;


