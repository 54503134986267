import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../CoustamComponents/Sidebar";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';
import { ProfileContext } from "../../../Services/ProfileProvider";
import moment from "moment";

const Certificate = () => {
    const [certificateHTML, setCertificateHTML] = useState('');
    const [iframeContent, setIframeContent] = useState('');

    const { userDetails } = useContext(ProfileContext);

    useEffect(() => {
        fetch('/certificate_appreciation.html')
            .then(response => response.text())
            .then(data => setCertificateHTML(data))
            .catch(error => console.error("Error fetching HTML file:", error));
    }, []);

    useEffect(() => {
        if (certificateHTML && userDetails) {
            const updatedHTML = getCertificateHTMLWithDetails();
            console.log("Updated Iframe Content:", updatedHTML); // Log updated HTML for the iframe
            setIframeContent(updatedHTML);
        }
    }, [certificateHTML, userDetails]);
    

    const getCertificateHTMLWithDetails = () => {
        let updatedHTML = certificateHTML
            .replace('{{name}}', userDetails?.userName || '')
            .replace('{{partner}}', userDetails?.PartnershipId || '')
            .replace('{{email}}', userDetails?.email || '')
            .replace('{{RegistrationDate}}', moment(userDetails?.createdAt).format('DD-MM-YYYY') || '');
        return updatedHTML;
    };

    const exportToPDF = () => {
        const doc = new jsPDF('landscape', 'mm', 'a4');
    
        // Create a temporary div to hold the content for exporting
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = getCertificateHTMLWithDetails(); // Get the HTML directly
    
        // Set up the styles needed to make the temporary div look like the iframe
        document.body.appendChild(tempDiv); // Add it to the body for rendering
        html2canvas(tempDiv).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const imgWidth = 300; // A4 width in mm for landscape
            const pageHeight = 300; // A4 height in mm for landscape
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = 20;
    
            doc.addImage(imgData, 'PNG', 60, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
    
            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }
    
            doc.save('Partner_Certificate.pdf');
    
            // Clean up the temporary div after exporting
            document.body.removeChild(tempDiv);
        });
    };
    
    
    const showNotification = () => {
        let notifyContainer = document.getElementsByClassName("notification-container")[0];
        if (notifyContainer.classList.contains("d-none")) {
            notifyContainer.classList.add("active");
            notifyContainer.classList.remove("d-none");
        } else {
            notifyContainer.classList.add("d-none");
            notifyContainer.classList.remove("active");
        }
    };

    return (
        <>
            <div className="wrapper dashboard_page">
                <div className="page-body d-flex align-items-stretch">
                    <Sidebar />
                    <div className="page-content page-content-certificate">
                        <div className="bg-dash-dark-2 py-4">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="main_search">
                                        {/* Search input can be added here if needed */}
                                    </div>
                                    <div className="nofti_bar">
                                        <button className="nofti_btn" onClick={showNotification}>
                                            <span className="alert_badge"></span>
                                            <i className="ri-notification-4-fill"></i>
                                        </button>
                                        <div className="notification-container d-none">
                                            <h3>Notifications</h3>
                                            <label className="notification new text-center" htmlFor="size_1">No notification</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <section>
                            <div className="pg_title">
                                <div className="d-flex align-items-start justify-content-between mb-2 mb-md-3">
                                    <div>
                                        <h2 className="h5 mb-0 mb-3">Certificate</h2>
                                        <nav aria-label="breadcrumb mb-0">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><a href="/">Dashboard</a></li>
                                                <li className="breadcrumb-item active" aria-current="page">Certificate</li>
                                            </ol>
                                        </nav>
                                    </div>
                                    <button className="btn btn-light bg-white export_btn" onClick={exportToPDF}>
                                        <i className="ri-download-2-line me-2 ri-xl"></i> Export
                                    </button>
                                </div>
                            </div>
                            <div className="grid-container row g-4">
                                <div className="col-lg-12 certificate-margin">
                                    {/* Render the dynamically updated certificate inside an iframe */}
                                    <iframe
                                        title="Certificate"
                                        style={{ width: '100%', height: '500px', border: 'none' }}
                                        srcDoc={iframeContent}
                                    />
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Certificate;
