import React, { useEffect, createContext, useState } from 'react';
import AuthService from '../AuthService';
import { alertErrorMessage } from '../CustomAlertMessage';

export const ProfileContext = createContext();

export function ProfileProvider(props) {
  
  const [userDetails, setUserDetails] = useState([]);
  const token = sessionStorage.getItem('token');
  const [loginDetails, setLoginDetails] = useState([]);

  const handleUserDetails = async () => {
    await AuthService.getuserProfile().then(async (result) => {
      if (result?.data) {
        try {
          setUserDetails(result?.data);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        alertErrorMessage(result?.message);
      }
    });
  };

  useEffect(() => {
    if (token) {
      handleUserDetails();
    }
  }, [token]);

  return (
    <ProfileContext.Provider value={{ userDetails, setUserDetails, handleUserDetails, setLoginDetails, loginDetails, }}>
      {props.children}
    </ProfileContext.Provider>
  );
}
