import React, { useContext, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { alertErrorMessage, alertSuccessMessage } from "../../../Services/CustomAlertMessage";
import AuthService from "../../../Services/AuthService";
import { ProfileContext } from "../../../Services/ProfileProvider";

const LoginPage = () => {

    const [formData, setFormData] = useState({ emailId: "", password: "" });
    const [showPass, setshowPass] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { setLoginDetails } = useContext(ProfileContext);

    const handleInput = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleLoginSubmit = async (event) => {
        event.preventDefault();
        const { emailId, password } = formData;
        if (!emailId) {
            alertErrorMessage('Please Enter Email Address');
            return;
        } if (!password) {
            alertErrorMessage('Please Enter Password');
            return;
        }
        setIsLoading(true);
        try {
            const result = await AuthService.login(formData);
            if (result?.success) {
                setIsLoading(false);
                alertSuccessMessage(result?.message);
                sessionStorage.setItem("token", result.token);
                sessionStorage.setItem("emailAddress", result.data.email);
                setLoginDetails(result);
                navigate("/dashboard");
            } else {
                setIsLoading(false);
                alertErrorMessage(result?.message);
            }
        } catch (error) {
            setIsLoading(false);
            alertErrorMessage(error);
        }
    };

    return (
        <div>
            <section className="section-padding login_sec">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-12 ">
                            <div className="login-card" >
                                <form className="custom-form   " onSubmit={handleLoginSubmit}>
                                    <div className=" d-block mb-5" >

                                        <div className=" mb-2  mb-md-4">
                                            <img src="images/logo.svg" className="navbar-brand img-fluid" width="300"/>
                                        </div>
                                        <h3 className="mb-2" >
                                            <strong className="text-dark" > Welcome Partner to CV Trade <br/> Dashboard 🌟</strong>
                                        </h3>
                                        <p>Please login to your account of partner’s dashboard.</p>
                                    </div>
                                    <div className=" cfield_group login_group">
                                        <label className="mb-2"> Email Address </label>
                                        <div className="cfield mb-3">
                                            <input type="email" className="form-control" name="emailId" value={formData.emailId} onChange={handleInput} required />
                                        </div>
                                    </div>
                                    <div className=" cfield_group login_group">
                                        <label className="mb-2">Enter Password </label>
                                        <div className="cfield mb-3 ">
                                            <div className=" field-otp-box" >
                                                <input type={showPass ? 'text' : "password"} name="password" className="form-control" value={formData.password} onChange={handleInput} required />
                                                <button type="button" className="btn btn-icon btn_view_pass btn-white" onClick={() => { setshowPass(!showPass) }}>
                                                    <i className={`ri-eye${showPass ? "-line" : "-close-line"}`} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="mb-4 text-end" >
                                        <a className="text-dark" > Forgot Password? </a>
                                    </div> */}
                                    <button type="submit" className="btn custom-btn btn-gradient btn-border btn-xl w-100 mt-3">
                                        {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            : <span>Submit</span>}
                                    </button>

                                    <div className="cfield_group  sss_g login_group text-center mt-5 ">
                                        <span> Partnership With Us  </span>
                                        <Link to="https://cvtrade.io/partnership" target="_blank" className="text-decoration-underline ms-2 text-dark"> <b>Register Now</b></Link>
                                    </div>
                                    <div className="cfield_group  sss_g login_group text-center mt-4 ">
                                        <Link to="/ForgotPassword" className="text-decoration-underline ms-2 text-dark"> <b>Forgot Password?</b></Link>
                                    </div>
                                </form> 
                            </div>
                        </div> 
                        
                        <div className="col-lg-6 col-12 ">
                            <div className="login_img_card" >
                                <img src="images/login_img.png" alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                     
                </div>
            </section>
            
        </div>


    )
}

export default LoginPage