import React from 'react';
import Chart from 'react-apexcharts';

const UserGrowth = ({ categories, data }) => {
    const chartData = {
        series: [
          {
            name: 'Users',
            data: data,
          },
        ],
        options: {
          chart: {
            type: 'area',
            height: 350,
          },
          stroke: {
            curve: 'smooth',
            colors: ['#00E396'],
          },
          fill: {
            colors: ['#00E396'],
            opacity: 0.3,
          },
          xaxis: {
            categories: categories, // Use the categories from the API
          },
          tooltip: {
            y: {
              formatter: (val) => `${val} users`,
            },
          },
        },
      };
    
      return (
        <div>
          <Chart
            options={chartData.options}
            series={chartData.series}
            type="area"
            height={350}
          />
        </div>
      );
    };
    
export default UserGrowth;