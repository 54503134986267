
import React, { useEffect, useState } from "react";
import Sidebar from "../../CoustamComponents/Sidebar";
import AuthService from "../../../Services/AuthService";
import LoaderHelper from "../../../Services/Loading/LoaderHelper";
import moment from "moment";
import DataTable from "react-data-table-component";

const ComissionReport = () => {
    const [commissionData, setCommissionData] = useState([]);

    const getTotalComission = async () => {
        LoaderHelper.loaderStatus(true);
        try {
            const comissionPerDay = await AuthService.partner_monthly_payouts_list();
            if (comissionPerDay?.success) {
                setCommissionData(comissionPerDay?.data);
            }

        } finally { LoaderHelper.loaderStatus(false); }
    };

    const columns = [
        { name: 'Sr no.', selector: (row, index) => index + 1, wrap: true },
        { name: 'Date',  sort: true, selector: row => moment(row?.createdAt).format('MMMM Do YYYY'), wrap: true },
        { name: 'Comission ($)', selector: row => row?.credit_amount, wrap: true },

    ];




    useEffect(() => {
        getTotalComission()
    }, []);


    return (
        <> 
        <div className="wrapper dashboard_page ">
            <div className=" page-body d-flex align-items-stretch">
                <Sidebar />
                <div className="page-content">
                    <div className="bg-dash-dark-2 py-4">
                        <div className="container-fluid">
                            <div className="d-flex align-items-center justify-content-between" >
                                <h2 className="h5 mb-0"> Commission Report</h2>
                            </div>
                        </div>
                    </div>
                    <section>
                        <div className="grid-container row g-4" >
                            <div className="col-lg-12 " >


                                <DataTable
                                    pagination
                                    direction="auto"
                                    responsive
                                    subHeaderAlign="right"
                                    subHeaderWrap
                                    striped
                                    highlightOnHover
                                    fixedHeader
                                    columns={columns}
                                    data={commissionData}
                                />

                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>

      
        </>
    );
}

export default ComissionReport