

import React, { useEffect, useState } from "react";
import Sidebar from "../../CoustamComponents/Sidebar";
import AuthService from "../../../Services/AuthService";
import LoaderHelper from "../../../Services/Loading/LoaderHelper";
import moment from "moment";
import ReactPaginate from 'react-paginate';

import DataTable from "react-data-table-component";

const UserComissionReport = () => {
    const [commissionData, setCommissionData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(100);
    const [totalData, setTotalData] = useState()


    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected + 1);
    };

    const pageCount = totalData / itemsPerPage
    const skip = (currentPage - 1) * itemsPerPage;

    const getTotalComission = async (skip, limit) => {
        try {
            LoaderHelper.loaderStatus(true); 
            const comissionPerDay = await AuthService.tradingCommission(skip, limit);
            if (comissionPerDay?.success) {
                setCommissionData(comissionPerDay?.data);
                setTotalData(comissionPerDay?.totalCount)
            }

        } finally { LoaderHelper.loaderStatus(false); }
    };


    const columns = [
        { name: "Sr No.", wrap: true, selector: (row, index) => skip + 1 + index, },
        { name: "Date", selector: row => moment(row?.createdAt).format("MMM Do YYYY hh:mm A"), wrap: true },
        { name: "Asset", wrap: true, selector: row => row.short_name, },
        { name: "Fee",width:"120px", wrap: true, selector: row => row.fee, },
        { name: "Fee Type", width:"120px",wrap: true, selector: row => row.fee_type, },
        { name: "From User",width:"120px", wrap: true, selector: row => row.from_user, },
        { name: "From Order", width:"120px",wrap: true, selector: row => row.fromOrder, },
        { name: "Percentage",width:"120px",  wrap: true, selector: row => row.percentage, },
        { name: "Disbursed Amount", width:"150px", wrap: true, selector: row => row.amount, },
      ];



    useEffect(() => {
        getTotalComission(skip, 100)
    }, [currentPage, skip]);


    return (
        <>
            <div className="wrapper dashboard_page ">
                <div className=" page-body d-flex align-items-stretch">
                    <Sidebar />
                    <div className="page-content">
                        <div className="bg-dash-dark-2 py-4">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between" >
                                    <h2 className="h5 mb-0">Trading Commission Report</h2>
                                </div>
                            </div>
                        </div>
                        <section>
                            <div className="grid-container row g-4" >
                                <div className="col-lg-12 " >


                                    <DataTable
                                        direction="auto"
                                        responsive
                                        subHeaderAlign="right"
                                        subHeaderWrap
                                        striped
                                        highlightOnHover
                                        fixedHeader
                                        columns={columns}
                                        data={commissionData}

                                    />

                                </div>
                                {totalData > 5 ? <ReactPaginate
                                    pageCount={pageCount}
                                    onPageChange={handlePageChange}
                                    containerClassName={'customPagination'}
                                    activeClassName={'active'}
                                /> : ""}
                            </div>
                        </section>
                    </div>
                </div>
            </div>


        </>
    );
}

export default UserComissionReport